<template>
  <div class="profile">
    <v-row>
      <v-col class="hidden-sm-and-down" cols="0" md="3">
        <MenuNavigator class="menu-navigator" />
        <v-divider class="my-5"></v-divider>
        <SupportCard />
      </v-col>

      <v-col cols="12" sm="12" md="9" class="pl-3 pl-md-9">
        <!-- <h2 class="mb-4">{{ $t("profile.update.title") }}</h2> -->
        <ProfileTitle class="mb-5" :category="category" />
        <v-form ref="userDataForm" v-model="isFormValid" v-if="billingData">
          <v-row>
            <v-col cols="12" sm="8" md="8" lg="6">
              <!-- ragione sociale -->
              <!-- :placeholder="$t('profile.update.companyPlaceholder')" -->
              <v-text-field
                v-model="billingData.person.company"
                :label="$t('profile.update.companyLabel')"
                persistent-placeholder
                :disabled="userDataFormDisabled"
                outlined
                dense
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="4" md="4" lg="4">
              <!-- codice cliente -->
              <!-- :placeholder="$t('profile.update.customerCodePlaceholder')" -->
              <v-text-field
                v-model="billingData.person.codInt"
                :label="$t('profile.update.customerCodeLabel')"
                persistent-placeholder
                :disabled="userDataFormDisabled"
                outlined
                dense
              ></v-text-field>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12" sm="6" md="6" lg="5">
              <!-- partita iva -->
              <!-- :placeholder="$t('profile.update.vatCodePlaceholder')" -->
              <v-text-field
                v-model="billingData.person.vatCode"
                :label="$t('profile.update.vatCodeLabel')"
                persistent-placeholder
                :disabled="userDataFormDisabled"
                outlined
                dense
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6" md="6" lg="5">
              <!-- codice fiscale -->
              <v-text-field
                v-model="billingData.person.fiscalCode"
                :label="$t('profile.update.fiscalCodeLabel')"
                :placeholder="$t('profile.update.fiscalCodePlaceholder')"
                :disabled="userDataFormDisabled"
                persistent-placeholder
                outlined
                dense
              ></v-text-field>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12" sm="6" md="6" lg="5">
              <!-- pec -->
              <!-- :placeholder="$t('profile.update.pecPlaceholder')" -->
              <v-text-field
                v-model="billingData.person.emailCertified"
                :label="$t('profile.update.pecLabel')"
                :rules="emailPecRules"
                persistent-placeholder
                :disabled="userDataFormDisabled"
                outlined
                dense
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6" md="6" lg="5">
              <!-- codice interscambio -->
              <!-- :placeholder="$t('profile.update.sdiCodePlaceholder')" -->
              <v-text-field
                v-model="billingData.person.vatSdiCode"
                :label="$t('profile.update.sdiCodeLabel')"
                persistent-placeholder
                :rules="sdiRules"
                :disabled="userDataFormDisabled"
                outlined
                dense
              ></v-text-field>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12" sm="6" md="6" lg="5">
              <!-- login -->
              <!-- :placeholder="$t('profile.update.loginPlaceholder')" -->
              <v-text-field
                v-model="billingData.login"
                :label="$t('profile.update.loginLabel')"
                persistent-placeholder
                :rules="sdiRules"
                :disabled="userDataFormDisabled"
                outlined
                dense
              ></v-text-field>
            </v-col>
          </v-row>
        </v-form>
        <h3>Modifica consenso privacy</h3>
        <div class="legal-agreement">
          <i18n path="profile.update.legal.text" tag="span">
            <template v-slot:link>
              <router-link
                :to="{
                  name: 'Page',
                  params: { pageName: 'privacy-policy-acquisto' }
                }"
              >
                {{ $t("profile.update.legal.privacyLink") }}
              </router-link>
            </template>
          </i18n>

          <v-checkbox
            false-value="false"
            true-value="true"
            dense
            color="primary"
            hide-details
            v-model="optIn3"
          >
            <template v-slot:label>
              <span
                v-html="$t('profile.update.legal.profilation')"
                @click="clicked"
              ></span>
            </template>
          </v-checkbox>

          <v-checkbox
            false-value="false"
            true-value="true"
            dense
            color="primary"
            hide-details
            v-model="optIn4"
          >
            <template v-slot:label>
              <span
                v-html="$t('profile.update.legal.marketing')"
                @click="clicked"
              ></span>
            </template>
          </v-checkbox>

          <v-checkbox
            false-value="false"
            true-value="true"
            dense
            color="primary"
            hide-details
            v-model="optIn6"
          >
            <template v-slot:label>
              <span
                v-html="$t('profile.update.legal.digitalMarketing')"
                @click="clicked"
              ></span>
            </template>
          </v-checkbox>
        </div>
        <v-row no-gutters class="justify-center justify-md-start">
          <v-btn
            class="mt-8 mb-16 my-sm-8"
            color="primary"
            depressed
            @click="updateUser()"
          >
            {{ $t("profile.update.submit") }}
          </v-btn>
        </v-row>
        <h3 class="mb-4">Cambio password</h3>
        <v-form ref="passwordUpdateForm" v-model="isPasswordValid">
          <v-row dense>
            <v-col cols="12" sm="6" md="6" lg="5">
              <v-text-field
                v-model="oldPassword"
                :label="$t('profile.update.oldPasswordLabel')"
                :placeholder="$t('profile.update.oldPasswordPlaceholder')"
                :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                :type="showPassword ? 'text' : 'password'"
                @click:append="toggleShowPassword"
                required
                outlined
                dense
              ></v-text-field>
            </v-col>
            <v-col cols="0" sm="6"></v-col>
            <v-col cols="12" sm="6" md="6" lg="5">
              <v-text-field
                v-model="password"
                :label="$t('profile.update.passwordLabel')"
                :placeholder="$t('profile.update.passwordPlaceholder')"
                :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                :type="showPassword ? 'text' : 'password'"
                :rules="passwordRules"
                @click:append="toggleShowPassword"
                required
                outlined
                dense
              ></v-text-field>
            </v-col>

            <v-col cols="12" sm="6" md="6" lg="5">
              <v-text-field
                v-model="passwordConfirm"
                :label="$t('profile.update.confirmPasswordLabel')"
                :placeholder="$t('profile.update.confirmPasswordPlaceholder')"
                :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                :type="showPassword ? 'text' : 'password'"
                :rules="passwordConfirmRules"
                @click:append="toggleShowPassword"
                required
                outlined
                dense
                clearable
              ></v-text-field>
            </v-col>
          </v-row>

          <v-row no-gutters class="justify-center justify-md-start">
            <v-btn
              class="mb-16"
              color="primary"
              depressed
              :disabled="!isPasswordValid"
              @click="updatePassword()"
            >
              {{ $t("profile.update.modifyPassword") }}
            </v-btn>
          </v-row>
        </v-form>

        <h3 class="mb-4">Elimina account</h3>
        <v-form ref="form">
          <v-row dense>
            <v-col cols="0" sm="6"></v-col>
            <v-col cols="12">
              <v-card-text>
                Tramite questa azione il tuo account verrà eliminato.
                <br />
                Quindi non potrai più usufruire dei servizi online di
                Grosmarket, tra cui ad esempio l’inoltro di ordini online, la
                consultazione dei prezzi, il download delle schede tecniche,
                etc.<br />

                La cancellazione è possibile solo se non si hanno ordini online
                in consegna; in tal caso ti invitiamo a richiedere la
                cancellazione del tuo account solo dopo la consegna dell’ordine
                stesso.
              </v-card-text>
            </v-col>
          </v-row>

          <v-row no-gutters class="justify-center justify-md-start">
            <v-btn
              class="primary anonymize-account-btn"
              @click="anonymizeAccount()"
              :loading="loadingAnonymizeAccount"
              large
              depressed
            >
              <strong>Elimina account</strong>
            </v-btn>
          </v-row>
        </v-form>
        <br />
        <v-row>
          <v-col v-if="$platform_is_cordova && isBetaTester" class="mt-8">
            <a :href="developmentLink">Apri versione di test </a></v-col
          >
        </v-row>

        <v-dialog v-model="removeDialog" max-width="400">
          <div class="vuedl-layout__closeBtn" @click="removeDialog = false">
            ×
          </div>
          <v-card>
            <v-card-title class="anonymize-dialog-title">
              {{ $t("profile.anonymizeProfileDialog.title") }}
            </v-card-title>
            <v-card-text
              class="anonymize-dialog-text body-1 pt-8"
              style="font-size: 14px !important"
              v-html="$t('profile.anonymizeProfileDialog.text')"
            >
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>

              <v-btn
                text
                @click.prevent="anonymizeAccount(true)"
                @mousedown.stop
              >
                Si
              </v-btn>
              <v-btn class="secondary" text @click="removeDialog = false">
                No
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <RecaptchaDisclaimer></RecaptchaDisclaimer>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import RegistrationService from "@/service/grosmarketCustomService";
import categoryMixin from "~/mixins/category";
import clickHandler from "~/mixins/clickHandler";
import RecaptchaDisclaimer from "@/commons/components/RecaptchaDisclaimer.vue";
import MenuNavigator from "@/components/profile/MenuNavigator";
import SupportCard from "@/components/cards/SupportCard";
import UserService from "~/service/userService";
import ProfileTitle from "@/components/profile/ProfileTitle";

import { requiredValue, minLength, minNum } from "~/validator/validationRules";
import { req } from "~/validator/commons";

import { mapActions, mapGetters } from "vuex";
import { mapPersonInfo } from "~/service/ebsn";

export default {
  components: {
    MenuNavigator,
    SupportCard,
    ProfileTitle,
    RecaptchaDisclaimer
  },
  mixins: [categoryMixin, clickHandler],

  data() {
    return {
      billingData: null,
      isFormValid: false,
      isPasswordValid: false,

      loadingAnonymizeAccount: false,
      removeDialog: false,

      userDataFormDisabled: true,
      requiredValue: requiredValue,
      showPassword: false,
      oldPassword: null,
      password: null,
      passwordConfirm: null,
      passwordRules: [
        requiredValue("Digitare la password"),
        minLength(8, "La password deve essere lunga almeno 8 caratteri"),
        minNum(1, "La password deve contenere almeno una cifra")
      ],
      passwordConfirmRules: [
        v => !!v || "Confermare la password",
        v => v === this.password || "Le password non coincidono"
      ],
      emailPecRules: [
        v => {
          if (v) {
            return (
              !req(v) ||
              /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/.test(v) ||
              "Digitare una e-mail valida"
            );
          } else if (this.billingData && !this.billingData.person.vatSdiCode) {
            return "PEC o SDI obbligatori";
          } else {
            return true;
          }
        }
      ],
      sdiRules: [
        v => {
          if (v) {
            return /^[A-Z0-9]{6,7}$/.test(v) || "Codice SDI non valido";
          } else if (
            this.billingData &&
            !this.billingData.person.emailCertified
          ) {
            return "PEC o SDI obbligatori";
          } else {
            return true;
          }
        }
      ]
    };
  },
  computed: {
    ...mapPersonInfo({ optIn3: 3, optIn4: 4, optIn6: 6 }, "billingData.person"),
    ...mapGetters({
      isBetaTester: "cart/isBetaTester"
    }),
    developmentLink() {
      //da testare se device esiste veramente in vue.js
      // eslint-disable-next-line no-undef
      let platform = device ? device.platform.toLowerCase() : "ios";
      return "/mobile/develop/" + platform + "/index.html";
    }
  },
  methods: {
    ...mapActions({
      doLogout: "cart/doLogout"
    }),
    toggleShowPassword(e) {
      e.preventDefault();
      this.showPassword = !this.showPassword;
    },
    async updatePassword() {
      let that = this;
      try {
        this.error = null;
        this.loading = true;
        if (this.$refs.passwordUpdateForm.validate()) {
          let res = await RegistrationService.updatePassword(
            that.oldPassword,
            that.password
          );
          this.loading = false;
          if (res) {
            // nessuna operazione
            // that.oldPassword = that.password = that.passwordConfirm = "";
          } else {
            if (res.errors.length && res.errors[0].error) {
              that.error = res.errors[0].error;
            }
          }
        }
      } catch (response) {
        this.response = response;
      } finally {
        this.loading = false;
      }
    },
    async updateUser() {
      let that = this;
      try {
        this.loading = true;
        let res = await UserService.updateUserDetail(that.billingData);
        this.loading = false;
        if (res && !res.errors) {
          global.EventBus.$emit("success", {
            message: global.vm.$t("message.userDetailsUpdated")
          });
        } else {
          if (res.errors) {
            that.error = true;
          }
        }
      } catch (response) {
        this.response = response;
      } finally {
        this.loading = false;
      }
    },

    async anonymizeAccount(perfom = false) {
      this.removeDialog = true;
      if (perfom) {
        this.loadingAnonymizeAccount = true;
        let result = await RegistrationService.anonymizeAccountRequest();
        if (result.response.status === 0) {
          this.removeDialog = false;
          // se utente davvero anonimizzato -> logout
          await this.doLogout();
          this.loadingAnonymizeAccount = false;
          this.$router.push({ name: "Home", path: "/" });
        } else {
          this.responseAnonymazeAccount = result.response;
          this.removeDialog = false;
          this.loadingAnonymizeAccount = false;
        }
      }
    }
  },
  async mounted() {
    let that = this;
    try {
      this.loading = true;
      let res = await UserService.getUserDetail();
      this.loading = false;
      if (res && !res.errors) {
        this.billingData = res;
      } else {
        if (res.errors) {
          that.error = true;
        }
      }
    } catch (response) {
      this.response = response;
    } finally {
      this.loading = false;
    }
  }
};
</script>

<style scoped lang="scss">
.profile {
  .v-text-field {
    font-size: 14px;
    label {
      font-size: 14px;
    }
  }
  ::placeholder {
    font-size: 14px;
  }
  .legal-agreement {
    .v-input--selection-controls {
      margin-top: 8px;
    }
    @media #{map-get($display-breakpoints, 'sm-and-down')} {
      margin-top: 20px;
    }
  }

  @media #{map-get($display-breakpoints, 'xs-only')} {
    .v-input {
      font-size: 12px;
      .v-label {
        font-size: 12px;
      }
    }
  }
}
</style>
